/* You can add global styles to this file, and also import other style files */

html, body { 
    height: 100%; 
    line-height: unset;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-form-field:not(.origin) mat-select:not(.mat-select-disabled) {
    padding: 0;
    margin: 0;
}

:root {
    --edfx-secondary-blue: #005EFF;
}

.custom-dialog-container .mdc-dialog__surface {
    border-radius: 32px !important;
}

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

